import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import API from "../services/api";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const formData = new URLSearchParams();
      formData.append("username", username);
      formData.append("password", password);

      const response = await API.post("/token", formData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
  
      console.log("Full Axios response:", response);
      console.log("Response data:", response.data);
  
      if (response.data && response.data.access_token) {
        localStorage.setItem("token", response.data.access_token);
        navigate("/home");
      } else {
        alert("Invalid username or password.");
      }
    } catch (error) {
      console.error("Login failed:", error);
      alert("Login Error.");
    }
  };
  
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "20px", maxWidth: "800px", margin: "auto" }}>

      <h2>Kullanıcı Girişi</h2>
      <form onSubmit={handleLogin} style={{ display: "flex", flexDirection: "column", width: "300px" }}>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
          style={{ marginBottom: "10px", padding: "10px" }}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          style={{ marginBottom: "10px", padding: "10px" }}
        />
        <button type="submit" style={{ padding: "10px" }}>Login</button>
      </form>
    </div>
  );
}

export default Login;
