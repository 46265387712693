import React, { useCallback, useState, useEffect } from 'react';
import API from '../services/api';
import { useNavigate } from 'react-router-dom';

function CreateTask() {
  const [activeTab, setActiveTab] = useState('createTask');
  const [taskName, setTaskName] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [createMessage, setCreateMessage] = useState(null);
  const [updateMessage, setUpdateMessage] = useState(null);
  const [locations, setLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [locationPage, setLocationPage] = useState(1);
  const locationsPerPage = 10;

  const [locationSearchTerm, setLocationSearchTerm] = useState(''); // Search term for locations

  const [tasks, setTasks] = useState([]);
  const [taskSearchTerm, setTaskSearchTerm] = useState('');
  const [editingTaskId, setEditingTaskId] = useState(null);
  const [editingTaskName, setEditingTaskName] = useState('');
  const [editingTaskStatus, setEditingTaskStatus] = useState(true);
  const [taskPage, setTaskPage] = useState(1);
  const tasksPerPage = 10;
  const navigate = useNavigate();

  // Flag to indicate the first fetch of locations has been completed
  const [locationsFetched, setLocationsFetched] = useState(false);

  const fetchLocations = async () => {
    try {
      const response = await API.get('/get_locations', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setLocations(response.data.locations || []);
      setLocationsFetched(true); // Set the flag after locations are fetched
    } catch (error) {
      console.error('Error fetching locations:', error);
    }
  };

  const fetchTasks = useCallback(async () => {
    try {
      const response = await API.get('/get_task_with_locations', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setTasks(response.data.tasks); // Backend'den gelen yanıtı doğrudan state'e set ediyoruz
    } catch (error) {
      console.error('Error fetching tasks:', error);
    }
  }, []);

  // Fetch locations only once on component mount
  useEffect(() => {
    fetchLocations();
  }, []);

  // Fetch tasks only once locations are fetched successfully
  useEffect(() => {
    if (locationsFetched) {
      fetchTasks();
    }
  }, [locationsFetched, fetchTasks]); // Runs only once when locations are first fetched

  useEffect(() => {
    if (createMessage) {
      const timer = setTimeout(() => setCreateMessage(null), 5000);
      return () => clearTimeout(timer);
    }
  }, [createMessage]);

  useEffect(() => {
    if (updateMessage) {
      const timer = setTimeout(() => setUpdateMessage(null), 5000);
      return () => clearTimeout(timer);
    }
  }, [updateMessage]);

  const handleCreateTask = async (e) => {
    e.preventDefault();
    if (!taskName.trim()) {
      setCreateMessage('Task name is required.');
      return;
    }
    try {
      await API.post('/create_task', { name: taskName, isactive: isActive, location_ids: selectedLocations }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });
      setCreateMessage('Task created successfully');
      setTaskName('');
      setIsActive(true);
      setSelectedLocations([]);
      fetchTasks();
    } catch (error) {
      console.error('Error creating task:', error);
      setCreateMessage('Error creating task. Please try again.');
    }
  };

  const startEditingTask = (task) => {
    setEditingTaskId(task.id);
    setEditingTaskName(task.name);
    setEditingTaskStatus(task.isactive);
  };

  const handleSaveTaskEdit = async () => {
    try {
      await API.put(`/update_task/${editingTaskId}`, { name: editingTaskName, isactive: editingTaskStatus }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });
      setUpdateMessage(`Task '${editingTaskName}' updated successfully.`);
      setEditingTaskId(null);
      fetchTasks();
    } catch (error) {
      console.error('Error saving task:', error);
      setUpdateMessage('Error saving task. Please try again.');
    }
  };

  const handleCancelEdit = () => {
    if (editingTaskId) {
      setEditingTaskId(null);
      setEditingTaskName('');
      setEditingTaskStatus(true);
    } else {
      navigate('/home');
    }
  };

  const handleLocationSelect = (locationId) => {
    setSelectedLocations((prevSelected) =>
      prevSelected.includes(locationId)
        ? prevSelected.filter((id) => id !== locationId)
        : [...prevSelected, locationId]
    );
  };

  // Filter and paginate locations based on the search term
  const filteredLocations = locations
    .filter(location => location.name.toLowerCase().includes(locationSearchTerm.toLowerCase()))
    .slice((locationPage - 1) * locationsPerPage, locationPage * locationsPerPage);

  const filteredTasks = tasks
    .filter((task) => task.name.toLowerCase().includes(taskSearchTerm.toLowerCase()))
    .slice((taskPage - 1) * tasksPerPage, taskPage * tasksPerPage);

  const paginate = (pageSetter, page, items, itemsPerPage) => (
    Array.from({ length: Math.ceil(items.length / itemsPerPage) }, (_, index) => (
      <button
        key={index + 1}
        onClick={() => pageSetter(index + 1)}
        style={styles.pageButton}
        className={page === index + 1 ? 'active' : ''}
      >
        {index + 1}
      </button>
    ))
  );

  return (
    <div style={styles.container}>
      <div style={styles.buttonContainer}>
          <button type="button" onClick={() => navigate('/home')} style={styles.cancelButton}>
            Home
          </button>
      </div>


      <div style={styles.tabHeader}>
        <button
          style={activeTab === 'createTask' ? styles.activeTab : styles.tab}
          onClick={() => setActiveTab('createTask')}
        >
          Create Task
        </button>
        <button
          style={activeTab === 'existingTasks' ? styles.activeTab : styles.tab}
          onClick={() => setActiveTab('existingTasks')}
        >
          Existing Tasks
        </button>
      </div>

      <div style={styles.tabContent}>
        {activeTab === 'createTask' && (
          <div>
            {createMessage && <p style={styles.message}>{createMessage}</p>}
            <form onSubmit={handleCreateTask} style={styles.form}>
              <div style={styles.row}>
                <input
                  type="text"
                  placeholder="Task Name"
                  value={taskName}
                  onChange={(e) => setTaskName(e.target.value)}
                  required
                  style={styles.input}
                />
                <select
                  value={isActive.toString()}
                  onChange={(e) => setIsActive(e.target.value === 'true')}
                  style={styles.input}
                >
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </select>
              </div>

              <h3>Select Locations:</h3>
              <input
                type="text"
                placeholder="Search locations..."
                value={locationSearchTerm}
                onChange={(e) => setLocationSearchTerm(e.target.value)}
                style={{ width: '100%', padding: '10px', marginBottom: '10px', borderRadius: '4px', border: '1px solid #ddd' }}
              />
              <table style={styles.table}>
                <thead>
                  <tr>
                    <th style={styles.th}>Select</th>
                    <th style={styles.th}>Location Name</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredLocations.map((location) => (
                    <tr key={location.id}>
                      <td style={styles.td}>
                        <input
                          type="checkbox"
                          checked={selectedLocations.includes(location.id)}
                          onChange={() => handleLocationSelect(location.id)}
                        />
                      </td>
                      <td style={styles.td}>{location.name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>




            <div style={styles.pagination}>
              {paginate(setLocationPage, locationPage, locations, locationsPerPage)}
            </div>
            <div style={styles.buttonContainer}>
                <button type="submit" style={styles.createButton}>Create Task</button>
              </div>
            </form>

          </div>
        )}

        {activeTab === 'existingTasks' && (
          <div>
            {updateMessage && <p style={styles.message}>{updateMessage}</p>}
            <input
              type="text"
              placeholder="Search tasks..."
              value={taskSearchTerm}
              onChange={(e) => setTaskSearchTerm(e.target.value)}
              style={styles.searchInput}
            />
            <table style={styles.table}>
              <thead>
                <tr>
                  <th style={styles.th}>Task Name</th>
                  <th style={styles.th}>Status</th>
                  <th style={styles.th}>Locations</th>
                  <th style={styles.th}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredTasks.map((task) => (
                  <tr key={task.id}>
                    <td style={styles.td}>
                      {editingTaskId === task.id ? (
                        <input
                          type="text"
                          value={editingTaskName}
                          onChange={(e) => setEditingTaskName(e.target.value)}
                          style={styles.input}
                        />
                      ) : (
                        task.name
                      )}
                    </td>
                    <td style={styles.td}>
                      {editingTaskId === task.id ? (
                        <select
                          value={editingTaskStatus}
                          onChange={(e) => setEditingTaskStatus(e.target.value === 'true')}
                          style={styles.input}
                        >
                          <option value="true">Active</option>
                          <option value="false">Inactive</option>
                        </select>
                      ) : (
                        task.isactive ? "Active" : "Inactive"
                      )}
                    </td>
                    <td style={styles.td}>
                      {task.locations.join(', ')}
                    </td>
                    <td style={styles.td}>
                      {editingTaskId === task.id ? (
                        <>
                          <button onClick={handleSaveTaskEdit} style={styles.saveButton}>Save</button>
                          <button onClick={handleCancelEdit} style={styles.cancelButton}>Cancel</button>
                        </>
                      ) : (
                        <button onClick={() => startEditingTask(task)} style={styles.editButton}>Edit</button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div style={styles.pagination}>
              {paginate(setTaskPage, taskPage, tasks, tasksPerPage)}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const styles = {
  container: {
    padding: '20px',
    maxWidth: '800px',
    margin: 'auto',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    fontFamily: "'Arial', sans-serif",
  },
  tabHeader: {
    display: 'flex',
    borderBottom: '2px solid #ddd',
    marginBottom: '20px',
  },
  tab: {
    flex: 1,
    padding: '10px',
    cursor: 'pointer',
    textAlign: 'center',
    backgroundColor: '#f0f0f0',
    border: 'none',
    fontSize: '16px',
    color: '#333',
    fontWeight: 'bold',
    borderRadius: '4px 4px 0 0',
  },
  activeTab: {
    flex: 1,
    padding: '10px',
    cursor: 'pointer',
    textAlign: 'center',
    backgroundColor: '#ffffff',
    color: '#007bff',
    fontWeight: 'bold',
    borderBottom: '2px solid #007bff',
    borderRadius: '4px 4px 0 0',
  },
  tabContent: {
    padding: '20px',
    backgroundColor: '#f9f9f9',
    borderRadius: '0 0 8px 8px',
  },
  searchInput: {
    width: '100%',
    padding: '10px',
    marginBottom: '15px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    fontSize: '16px',
    outline: 'none',
    boxSizing: 'border-box',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: '10px',
  },
  th: {
    padding: '12px',
    borderBottom: '2px solid #ddd',
    fontWeight: 'bold',
    textAlign: 'left',
    backgroundColor: '#f2f2f2',
    color: '#333',
  },
  td: {
    padding: '10px',
    borderBottom: '1px solid #ddd',
    color: '#333',
    textAlign: 'left',
  },
  input: {
    width: '100%',
    padding: '8px',
    fontSize: '14px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    boxSizing: 'border-box',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  row: {
    display: 'flex',
    gap: '10px',
    flexWrap: 'wrap',
  },

  buttonContainer: {
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  
  cancelButton: {
    padding: '10px 20px',
    fontSize: '14px',
    color: '#fff',
    backgroundColor: '#f44336',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },

  createButton: {
    padding: '12px 20px',
    fontSize: '16px',
    backgroundColor: '#4CAF50',
    color: '#ffffff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',

  },

  editButton: {
    padding: '8px 12px',
    backgroundColor: '#007bff',
    color: '#ffffff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
  saveButton: {
    padding: '8px 12px',
    backgroundColor: '#4CAF50',
    color: '#ffffff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },

  pagination: {
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
    marginTop: '20px',
  },
  pageButton: {
    padding: '8px 12px',
    fontSize: '14px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },

  message: {
    fontSize: '16px',
    color: '#4CAF50',
    textAlign: 'center',
    marginBottom: '15px',
  },
};

export default CreateTask;
