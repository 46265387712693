import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'; // Eğer özel bir CSS dosyanız varsa
import App from './App'; // Ana bileşen App.js dosyasını import ediyoruz

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root') // React bileşenlerini root içine render ediyoruz.
);
