import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import API from '../services/api';

function UserEdit() {
  const [activeTab, setActiveTab] = useState('createUser');
  const [users, setUsers] = useState([]);
  const [formData, setFormData] = useState({ name: '', surname: '', role_id: 'client', isactive: 'true', username: '', password: '' });
  const [createMessage, setCreateMessage] = useState(null);
  const [editMessage, setEditMessage] = useState(null);
  const [passwordMessage, setPasswordMessage] = useState(null);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [userPage, setUserPage] = useState(1);
  const usersPerPage = 10;
  const [editingUserId, setEditingUserId] = useState(null);
  const [editingUserData, setEditingUserData] = useState({});
  const navigate = useNavigate();

  const fetchUsers = useCallback(async () => {
    try {
      const userResponse = await API.get('/get_current_user', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      const response = await API.get('/get_users', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      if (response.data && response.data.users) {
        const filteredUsers = response.data.users.filter(user => user.id !== userResponse.data.user_id);
        setUsers(filteredUsers);
        setError(null);
      } else {
        setError('Invalid response structure');
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Error fetching users.');
    }
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  // Mesajların kaybolmasını sağlayan effect
  useEffect(() => {
    const clearMessages = () => {
      setCreateMessage(null);
      setEditMessage(null);
      setPasswordMessage(null);
    };

    if (createMessage || editMessage || passwordMessage) {
      const timer = setTimeout(clearMessages, 5000); // 5 saniye sonra temizler
      return () => clearTimeout(timer); // Temizlik işlemi
    }
  }, [createMessage, editMessage, passwordMessage]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === 'isactive' ? value : value,
    }));
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditingUserData((prevData) => ({
      ...prevData,
      [name]: name === 'isactive' ? value : value,
    }));
  };

  const handleAddUser = async (e) => {
    e.preventDefault();
    try {
      const data = new FormData();
      data.append('name', formData.name);
      data.append('surname', formData.surname);
      data.append('role_id', formData.role_id);
      data.append('isactive', formData.isactive);
      data.append('username', formData.username);
      data.append('password', formData.password);
      await API.post('/create_user', data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setCreateMessage('User added successfully.');
      setUsers((prevUsers) => [...prevUsers, formData]);
      setFormData({ name: '', surname: '', role_id: 'client', isactive: 'true', username: '', password: '' });
      fetchUsers();
    } catch (error) {
      console.error('Error saving user:', error);
      setCreateMessage('Error saving user.');
    }
  };

  const startEditing = (user) => {
    setEditingUserId(user.id);
    setEditingUserData(user);
  };

  const handleSaveEdit = async () => {
    try {
      await API.put(`/update_user/${editingUserId}`, editingUserData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });
      setEditMessage('User updated successfully.');
      setEditingUserId(null);
      fetchUsers();
    } catch (error) {
      console.error('Error saving user:', error);
      setEditMessage('Error saving user.');
    }
  };

  const handleCancelEdit = () => {
    setEditingUserId(null);
    setEditingUserData({});
  };

  const generatePassword = async (userId) => {
    const randomPassword = Math.floor(1000 + Math.random() * 9000).toString(); // 4 basamaklı rastgele şifre
    try {
      await API.put(`/update_user_password/${userId}`, { password: randomPassword }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });
      setPasswordMessage(`New password : ${randomPassword}`);
    } catch (error) {
      console.error('Error updating password:', error);
      setPasswordMessage('Error generating new password.');
    }
  };

  const filteredUsers = users
    .filter(
      (user) =>
        user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.surname.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.role_id.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .slice((userPage - 1) * usersPerPage, userPage * usersPerPage);

  const paginate = (pageSetter, page, items, itemsPerPage) =>
    Array.from({ length: Math.ceil(items.length / itemsPerPage) }, (_, index) => (
      <button
        key={index + 1}
        onClick={() => pageSetter(index + 1)}
        style={styles.pageButton}
        className={page === index + 1 ? 'active' : ''}
      >
        {index + 1}
      </button>
    ));

  return (
    <div style={styles.container}>
      <div style={styles.cancelButtonContainer}>
          <button type="button" onClick={() => navigate('/home')} style={styles.cancelButton}>
            Home
          </button>
      </div>

      <div style={styles.tabHeader}>
        <button
          style={activeTab === 'createUser' ? styles.activeTab : styles.tab}
          onClick={() => setActiveTab('createUser')}
        >
          Kullanıcı Ekle
        </button>
        <button
          style={activeTab === 'existingUsers' ? styles.activeTab : styles.tab}
          onClick={() => setActiveTab('existingUsers')}
        >
          Mevcut Kullanıcılar
        </button>
      </div>

      <div style={styles.tabContent}>
        {activeTab === 'createUser' && (
          <div>
            {createMessage && <p style={styles.message}>{createMessage}</p>}
            {error && <p style={styles.error}>{error}</p>}

            <form onSubmit={handleAddUser} style={styles.form} autoComplete="off">
              <div style={styles.gridContainer}>
                <div style={styles.formGroup}>
                  <input
                    type="text"
                    name="name"
                    placeholder="Name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                    style={styles.input}
                    autoComplete="off"
                  />
                </div>
                <div style={styles.formGroup}>
                  <input
                    type="text"
                    name="surname"
                    placeholder="Surname"
                    value={formData.surname}
                    onChange={handleInputChange}
                    required
                    style={styles.input}
                    autoComplete="off"
                  />
                </div>
                <div style={styles.formGroup}>
                  <input
                    type="text"
                    name="username"
                    placeholder="Username"
                    value={formData.username}
                    onChange={handleInputChange}
                    required
                    style={styles.input}
                    autoComplete="off"
                  />
                </div>
                <div style={styles.formGroup}>
                  <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={formData.password}
                    onChange={handleInputChange}
                    required
                    style={styles.input}
                    autoComplete="off"
                  />
                </div>
                <div style={styles.formGroup}>
                  <select
                    name="role_id"
                    value={formData.role_id}
                    onChange={handleInputChange}
                    style={styles.input}
                  >
                    <option value="client">Client</option>
                    <option value="admin">Admin</option>
                  </select>
                </div>
                <div style={styles.formGroup}>
                  <select
                    name="isactive"
                    value={formData.isactive}
                    onChange={handleInputChange}
                    style={styles.input}
                  >
                    <option value="true">Active</option>
                    <option value="false">Inactive</option>
                  </select>
                </div>
              </div>
              <div style={styles.buttonContainer}>
                <button type="submit" style={styles.saveButton}>
                  Add User
                </button>
              </div>
            </form>
          </div>
        )}

        {activeTab === 'existingUsers' && (
          <div>
            {editMessage && <p style={styles.message}>{editMessage}</p>}
            {passwordMessage && <p style={styles.message}>{passwordMessage}</p>}
            <h3>Mevcut Kullanıcılar</h3>
            <input
              type="text"
              placeholder="Kullanıcı ara..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={styles.searchInput}
              autoComplete="off"
            />
            <table style={styles.table}>
              <thead>
                <tr>
                  <th style={styles.th}>Name</th>
                  <th style={styles.th}>Surname</th>
                  <th style={styles.th}>Username</th>
                  <th style={styles.th}>Role ID</th>
                  <th style={styles.th}>Active</th>
                  <th style={styles.th}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers.map((user) => (
                  <tr key={user.id}>
                    {editingUserId === user.id ? (
                      <>
                        <td style={styles.td}>
                          <input
                            type="text"
                            name="name"
                            value={editingUserData.name}
                            onChange={handleEditInputChange}
                            style={styles.input}
                          />
                        </td>
                        <td style={styles.td}>
                          <input
                            type="text"
                            name="surname"
                            value={editingUserData.surname}
                            onChange={handleEditInputChange}
                            style={styles.input}
                          />
                        </td>
                        <td style={styles.td}>
                          <input
                            type="text"
                            name="username"
                            value={editingUserData.username}
                            onChange={handleEditInputChange}
                            style={styles.input}
                          />
                        </td>
                        <td style={styles.td}>
                          <select
                            name="role_id"
                            value={editingUserData.role_id}
                            onChange={handleEditInputChange}
                            style={styles.input}
                          >
                            <option value="client">Client</option>
                            <option value="admin">Admin</option>
                          </select>
                        </td>
                        <td style={styles.td}>
                          <select
                            name="isactive"
                            value={editingUserData.isactive}
                            onChange={handleEditInputChange}
                            style={styles.input}
                          >
                            <option value="true">Active</option>
                            <option value="false">Inactive</option>
                          </select>
                        </td>
                        <td style={styles.td}>
                          <button onClick={handleSaveEdit} style={styles.saveButton}>
                            Save
                          </button>
                          <button onClick={handleCancelEdit} style={styles.cancelButton}>
                            Cancel
                          </button>
                        </td>
                      </>
                    ) : (
                      <>
                        <td style={styles.td}>{user.name}</td>
                        <td style={styles.td}>{user.surname}</td>
                        <td style={styles.td}>{user.username}</td>
                        <td style={styles.td}>{user.role_id}</td>
                        <td style={styles.td}>{user.isactive === "true" ? 'Active' : 'Inactive'}</td>
                        <td style={styles.td}>
                          <button onClick={() => startEditing(user)} style={styles.editButton}>
                            Edit
                          </button>
                          {/* Generate Password butonu sadece düzenlenmeyen satırlarda gösterilecek */}
                          {editingUserId !== user.id && (
                            <button onClick={() => generatePassword(user.id)} style={styles.generateButton}>
                              Generate Password
                            </button>
                          )}
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>

            <div style={styles.pagination}>
              {paginate(setUserPage, userPage, users, usersPerPage)}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const styles = {
  container: {
    padding: '20px',
    maxWidth: '1200px',
    margin: 'auto',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  tabHeader: {
    display: 'flex',
    borderBottom: '1px solid #ddd',
    marginBottom: '20px',
  },
  tab: {
    flex: 1,
    padding: '10px',
    cursor: 'pointer',
    textAlign: 'center',
    backgroundColor: '#f0f0f0',
    border: 'none',
    fontSize: '16px',
  },
  activeTab: {
    flex: 1,
    padding: '10px',
    cursor: 'pointer',
    textAlign: 'center',
    backgroundColor: '#ffffff',
    borderBottom: '2px solid #007bff',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  tabContent: {
    padding: '20px',
  },
  title: {
    marginBottom: '20px',
    color: '#333',
    fontSize: '24px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  searchInput: {
    marginBottom: '20px',
    marginTop: '10px',
    padding: '10px',
    width: '100%',
    borderRadius: '4px',
    border: '1px solid #ddd',
    fontSize: '16px',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    padding: '10px',
    borderBottom: '1px solid #ddd',
    textAlign: 'left',
    fontWeight: 'bold',
  },
  td: {
    padding: '10px',
    borderBottom: '1px solid #ddd',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '15px',
  },
  formGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    padding: '8px',
    fontSize: '16px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '15px',
  },
  saveButton: {
    padding: '12px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '6px',
    transition: 'background-color 0.3s',
    textAlign: 'center',
  },
  cancelButtonContainer: {
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cancelButton: {
    padding: '10px 20px',
    fontSize: '14px',
    color: '#fff',
    backgroundColor: '#f44336',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
  editButton: {
    padding: '8px 12px',
    backgroundColor: '#2196F3',
    color: 'white',
    border: 'none',
    fontSize: '14px',
    cursor: 'pointer',
    borderRadius: '5px',
    transition: 'background-color 0.3s',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
  },
  generateButton: {
    padding: '8px 12px',
    backgroundColor: '#FFC107',
    color: 'white',
    border: 'none',
    fontSize: '14px',
    cursor: 'pointer',
    borderRadius: '5px',
    transition: 'background-color 0.3s',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
    marginTop: '20px',
  },
  pageButton: {
    padding: '8px 12px',
    fontSize: '14px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
  message: {
    marginBottom: '20px',
    fontSize: '16px',
    color: '#4CAF50',
    textAlign: 'center',
  },
  error: {
    marginBottom: '20px',
    fontSize: '16px',
    color: '#f44336',
    textAlign: 'center',
  },
};

export default UserEdit;
