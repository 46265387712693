import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import API from "../services/api";
import { QrReader } from "react-qr-reader";

function Home() {
  const [role, setRole] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showScanner, setShowScanner] = useState(false);
  const [scannedData, setScannedData] = useState(null);
  const [devices, setDevices] = useState([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState(null);
  const [photo, setPhoto] = useState(null); // Fotoğraf için state
  const [currentTaskId, setCurrentTaskId] = useState(null); // Geçerli task ID'si
  const [errorMessage, setErrorMessage] = useState(null); // Hata mesajı için state
  const tasksPerPage = 5;
  const [showPhotoModal, setShowPhotoModal] = useState(false);
  const [showPhoto, setShowPhoto] = useState(false); // Fotoğraf görünürlüğü için state
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRole = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          alert("Please login first");
          navigate("/");
          return;
        }

        const response = await API.get("/get_user_role", {
          headers: { Authorization: `Bearer ${token}` },
        });

        setRole(response.data.role);
        if (response.data.role === "client") {
          fetchActiveTasks();
        }
      } catch (error) {
        console.error("Error fetching role", error);
        alert("Session expired. Please log in again.");
        navigate("/");
      }
    };

    const fetchActiveTasks = async () => {
      try {
        const response = await API.get("/get_active_tasks", {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
        setTasks(response.data.tasks || []);
      } catch (error) {
        console.error("Error fetching tasks", error);
      }
    };

    const getDevices = async () => {
      try {
        const allDevices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = allDevices.filter(
          (device) => device.kind === "videoinput"
        );
        setDevices(videoDevices);
        if (videoDevices.length > 0) {
          setSelectedDeviceId(videoDevices[0].deviceId);
        }
      } catch (error) {
        console.error("Error accessing media devices:", error);
      }
    };

    fetchRole();
    getDevices();
  }, [navigate]);



  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  const formattedPhoto = photo
  ? photo.startsWith("data:image/png;base64,")
    ? photo
    : `data:image/png;base64,${photo}`
  : null; // Eğer photo boşsa null olarak ayarlanır

  const handleScanResult = async (result) => {
    if (result) {
      const locationId = result.text;

      if (isSubmitting) return;
      setIsSubmitting(true);
      try {
        const response = await API.post(
          "/create_task_track/",
          {
            task_id: currentTaskId,
            location_id: locationId,
            photo_base64: formattedPhoto,
            
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );
  
        const locationName = response.data.location_name || "Bilinmeyen Lokasyon";
        setScannedData(`Başarılı Tarama, Lokasyon: ${locationName}`);
        setErrorMessage(null);
  
        setShowPhoto(true);
        

        setTimeout(() => {
          setScannedData(null);
        }, 5000);
      } catch (error) {
        console.error("Error creating task track", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.detail ===
          "This location is not associated with the given task."
        ) {
          setErrorMessage("Konum, bu devriyede bulunmamaktadır.");
          
          setTimeout(() => {
            setErrorMessage(null);
          }, 5000);
          


        } else {
          setErrorMessage("Görev kaydı oluşturulurken hata oluştu.");
          setTimeout(() => {
            setErrorMessage(null);
          }, 5000);
        }
      } finally {
        setShowScanner(false);
        setTimeout(() => {
          setShowPhoto(false);
        }, 5000);

      }
    }
  };
  



  const openPhotoModal = async () => {
    setShowPhotoModal(true);
    try {
      // Kamerayı başlat
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      const video = document.getElementById("photoVideo");
      video.srcObject = stream;
      video.play();
    } catch (error) {
      console.error("Error accessing camera:", error);
      alert("Kamera erişimi başarısız. Lütfen tarayıcı izinlerini kontrol edin.");
    }
  };
  
  const closePhotoModal = () => {
    setShowPhotoModal(false);
  
    // Kamerayı durdur
    const video = document.getElementById("photoVideo");
    if (video && video.srcObject) {
      video.srcObject.getTracks().forEach((track) => track.stop());
      video.srcObject = null;
    }
  };
  
  const takePhoto = () => {
    const video = document.getElementById("photoVideo");
  
    if (video) {
      const canvas = document.createElement("canvas");
      canvas.width = 300;
      canvas.height = 300;
  
      const ctx = canvas.getContext("2d");
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
  
      const photoData = canvas.toDataURL("image/png");
      setPhoto(photoData);
      setShowPhoto(true);
    }
    closePhotoModal()

  };

  const indexOfLastTask = currentPage * tasksPerPage;
  const indexOfFirstTask = indexOfLastTask - tasksPerPage;
  const currentTasks = tasks.slice(indexOfFirstTask, indexOfLastTask);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div style={styles.container}>
      {scannedData && (
        <div style={{ marginTop: "20px" }}>
          <h3>{scannedData}</h3>
        </div>
      )}
      {errorMessage && (
        <div style={styles.errorContainer}>
          <p>{errorMessage}</p>
        </div>
      )}
      {showScanner && (
        <div style={styles.showScannerOverlay}>
          <div style={styles.showScannerContainer}>
            <div style={styles.controlsContainer}>
              <select
                onChange={(e) => setSelectedDeviceId(e.target.value)}
                value={selectedDeviceId}
                style={styles.selectCamera}
              >
                {devices.map((device) => (
                  <option key={device.deviceId} value={device.deviceId}>
                    {device.label || `Camera ${device.deviceId}`}
                  </option>
                ))}
              </select>
              <button
                onClick={() => setShowScanner(false)}
                style={styles.cancelButton}
              >
                İptal
              </button>
            </div>
            <QrReader
              delay={300}
              onResult={(result, error) => {
                if (result) {
                  handleScanResult(result);
                }
                if (error) {
                  console.error("QR Scan Error:", error);
                }
              }}
              containerStyle={{ width: "100%", height: "100%" }}
              videoStyle={{ width: "100%", height: "100%", objectFit: "cover" }}
              constraints={
                selectedDeviceId ? { deviceId: selectedDeviceId } : undefined
              }
            />
          </div>
        </div>
      )}

{showPhotoModal && (
  <div style={styles.showScannerOverlay}>
    <div style={styles.showScannerContainer}>
      <div style={styles.controlsContainer}>
        <select
          onChange={(e) => setSelectedDeviceId(e.target.value)}
          value={selectedDeviceId}
          style={styles.selectCamera}
        >
          {devices.map((device) => (
            <option key={device.deviceId} value={device.deviceId}>
              {device.label || `Camera ${device.deviceId}`}
            </option>
          ))}
        </select>
        <button onClick={takePhoto} style={styles.button}>
          Çek
        </button>
        <button onClick={closePhotoModal} style={styles.cancelButton}>
          İptal
        </button>
      </div>
      <video
        id="photoVideo"
        autoPlay
        style={{ width: "100%", height: "100%", objectFit: "cover" }}
      ></video>
    </div>
  </div>
)}


      {role === "client" ? (
        <div style={styles.card}>
          {tasks.length === 0 ? (
            <p>No active tasks found.</p>
          ) : (
            <table style={styles.table}>
              <thead>
                <tr>
                  <th style={styles.th}>DEVRİYE ADI</th>
                  <th style={styles.th}></th>
                </tr>
              </thead>
              <tbody>
                {currentTasks.map((task) => (
                  <tr key={task.id}>
                    <td style={styles.td}>{task.name}</td>
                    <td style={{ ...styles.td, ...styles.tdButtonContainer }}>
                      <button
                        style={styles.button}
                        onClick={() => {
                          setCurrentTaskId(task.id);
                          setShowScanner(true);
                        }}
                      >
                        Scan QR
                      </button>
                      <button
                        style={styles.button}
                        onClick={() => {
                          setCurrentTaskId(task.id);
                          openPhotoModal(); // Fotoğraf çekme modalini açıyoruz
                        }}
                      >
                        Fotoğraf Çek
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          <div style={styles.pagination}>
            {Array.from(
              { length: Math.ceil(tasks.length / tasksPerPage) },
              (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => paginate(index + 1)}
                  style={
                    currentPage === index + 1
                      ? styles.activePageButton
                      : styles.pageButton
                  }
                >
                  {index + 1}
                </button>
              )
            )}
          </div>


          {showPhoto && photo && (
  <div style={{ marginBottom: "20px" }}>
    <h4>Çekilen Fotoğraf:</h4>
    <img
      src={photo}
      alt="Preview"
      style={{
        width: "150px",
        height: "150px",
        borderRadius: "8px",
        border: "1px solid #ccc",
      }}
    />
  </div>
)}




        </div>
      ) : role === "admin" ? (
        <div style={styles.card}>
          <h3>Yönetim Paneli</h3>
          <div style={styles.buttonContainer}>
            <button style={styles.button} onClick={() => navigate("/user-edit")}>Kullanıcı</button>
            <button style={styles.button} onClick={() => navigate("/create-location")}>Create Location</button>
            <button style={styles.button} onClick={() => navigate("/create-task")}>Create Task</button>
          </div>
        </div>
      ) : (
        <p style={{ textAlign: "center" }}>Loading...</p>
      )}
  
      <div style={styles.footerButtons}>
        <button onClick={() => navigate("/change-password")} style={styles.button}>Change Password</button>
        <button onClick={handleLogout} style={styles.logoutButton}>Logout</button>
      </div>
    </div>
  );
}

const styles = {
  errorContainer: {
    marginTop: "20px",
    padding: "10px",
    border: "1px solid #f44336",
    borderRadius: "5px",
    backgroundColor: "#ffebee",
    color: "#f44336",
    textAlign: "center",
  },
 
  
  container: {
    padding: '20px',
    maxWidth: '800px',
    margin: 'auto',
    textAlign: 'center',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },

  showScannerOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    zIndex: 1000,
  },
  showScannerContainer: {
    position: "relative",
    width: "100%", 
    maxWidth: "700px", 
    height: "100%",
    maxHeight: "350px", 
    marginTop: "90px",
    backgroundColor: "#fff",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    transform: "translateY(0)",
    zIndex: 1001,
  },
  controlsContainer: {
    display: "flex",
    gap: "10px",
    marginTop: "40px",
  },
  cancelButton: {
    padding: "10px 20px",
    backgroundColor: "#f44336",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "16px",
    zIndex: 1002,
  },
  selectCamera: {
    padding: "10px 20px",
    fontSize: "16px",
    zIndex: 1002,
  },

  toggleCameraButton: {
    position: "absolute",
    bottom: "60px",
    padding: "10px 20px",
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "16px",
    zIndex: 1100,
  },

  card: {
    border: "1px solid #ddd",
    borderRadius: "8px",
    padding: "20px",
    marginBottom: "20px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "10px",
  },
  th: {
    padding: "10px",
    borderBottom: "1px solid #ddd",
    fontWeight: "bold",
    textAlign: "left",
    whiteSpace: "nowrap", 
  },
  td: {
    padding: "10px",
    borderBottom: "1px solid #ddd",
    textAlign: "left",
    wordBreak: "break-word", 
  },
  tdButtonContainer: {
    display: "flex",
    justifyContent: "flex-end", // Butonu sağa yasla
  },

  button: {
    padding: "12px 12px", // Buton boyutunu daha da küçülttük
    fontSize: "14px", // Yazı boyutunu daha da küçülttük
    cursor: "pointer",
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    borderRadius: "8px",
    transition: "background-color 0.3s",
    width: "100%",
    maxWidth: "150px", // Maksimum genişliği daha da küçülttük
    textAlign: "center",
    margin: "6px 0", // Margin'i biraz daha küçülttük
  },

  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "10px", // Aradaki boşluğu biraz daha küçülttük
    flexWrap: "wrap",
  },

  footerButtons: {
    display: "flex",
    justifyContent: "space-between",
    gap: "10px", // Aradaki boşluğu küçülttük
    flexWrap: "wrap",
  },

  logoutButton: {
    padding: "12px 12px", // Diğer butonlarla aynı hale getirdik
    fontSize: "14px", // Yazı boyutunu küçülttük
    cursor: "pointer",
    backgroundColor: "#f44336",
    color: "white",
    border: "none",
    borderRadius: "8px",
    transition: "background-color 0.3s",
    width: "100%", // Eşit genişlikte olması için
    maxWidth: "150px", // Maksimum genişliği daha da küçülttük
    textAlign: "center",
    margin: "6px 0", // Margin'i biraz daha küçülttük
  },

  changePasswordButton: {
    padding: "15px 20px",
    fontSize: "18px",
    cursor: "pointer",
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    borderRadius: "8px",
    transition: "background-color 0.3s",
    maxWidth: "200px",
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
    gap: "5px",
    marginTop: "10px",
  },
  pageButton: {
    padding: "5px 10px",
    fontSize: "14px",
    cursor: "pointer",
    border: "1px solid #ddd",
    borderRadius: "5px",
  },
  activePageButton: {
    padding: "5px 10px",
    fontSize: "14px",
    cursor: "pointer",
    backgroundColor: "#4CAF50",
    color: "white",
    borderRadius: "5px",
    border: "1px solid #ddd",
  },


};

export default Home;
