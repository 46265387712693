import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import API from '../services/api';
import QRCode from 'qrcode';

function LocationEdit() {
  const [activeTab, setActiveTab] = useState('createLocation');
  const [locations, setLocations] = useState([]);
  const [formData, setFormData] = useState({ name: '', isactive: 'true' });
  const [createMessage, setCreateMessage] = useState(null);
  const [updateMessage, setUpdateMessage] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  /* eslint-disable no-unused-vars */
  const [currentPage] = useState(1);
  /* eslint-enable no-unused-vars */
  const locationsPerPage = 10;
  const [editingLocationId, setEditingLocationId] = useState(null);
  const [editingLocationData, setEditingLocationData] = useState({});
  const navigate = useNavigate();

  const fetchLocations = useCallback(async () => {
    try {
      const response = await API.get('/get_locations', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setLocations(response.data.locations);
    } catch (error) {
      console.error('Error fetching locations:', error);
      setUpdateMessage('Error fetching locations.');
    }
  }, []);

  useEffect(() => {
    fetchLocations();
  }, [fetchLocations]);

  useEffect(() => {
    if (createMessage) {
      const timer = setTimeout(() => setCreateMessage(null), 5000);
      return () => clearTimeout(timer);
    }
  }, [createMessage]);

  useEffect(() => {
    if (updateMessage) {
      const timer = setTimeout(() => setUpdateMessage(null), 5000);
      return () => clearTimeout(timer);
    }
  }, [updateMessage]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === 'isactive' ? value : value,
    }));
  };

  const handleAddLocation = async (e) => {
    e.preventDefault();
    try {
      const response = await API.post('/create_location', formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });
      setCreateMessage('Location added successfully.');
      setLocations((prevLocations) => [...prevLocations, response.data.location]);
      setFormData({ name: '', isactive: 'true' });
    } catch (error) {
      console.error('Error saving location:', error);
      setCreateMessage('Error saving location.');
    }
  };

  const startEditing = (location) => {
    setEditingLocationId(location.id);
    setEditingLocationData(location);
  };

  const handleSaveEdit = async () => {
    try {
      await API.put(`/update_location/${editingLocationId}`, editingLocationData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });
      setUpdateMessage('Location updated successfully.');
      setEditingLocationId(null);
      fetchLocations();
    } catch (error) {
      console.error('Error saving location:', error);
      setUpdateMessage('Error saving location.');
    }
  };

  const generateQRCode = async (id) => {
    try {
      const qrCodeDataUrl = await QRCode.toDataURL(id);
      const printWindow = window.open('', '_blank');
      printWindow.document.write(`<img src="${qrCodeDataUrl}" onload="window.print();window.close()" />`);
      printWindow.document.close();
    } catch (error) {
      console.error('Error generating QR code:', error);
      setUpdateMessage('Error generating QR code.');
    }
  };

  const handleCancelEdit = () => {
    setEditingLocationId(null);
    setEditingLocationData({});
  };

  const filteredLocations = locations.filter((location) =>
    location.name.toLowerCase().includes(searchTerm.toLowerCase())
  ).slice((currentPage - 1) * locationsPerPage, currentPage * locationsPerPage);

  return (
    <div style={styles.container}>
      <div style={styles.cancelButtonContainer}>
        <button type="button" onClick={() => navigate('/home')} style={styles.cancelButton}>
          Home
        </button>
      </div>

      <div style={styles.tabHeader}>
        <button
          style={activeTab === 'createLocation' ? styles.activeTab : styles.tab}
          onClick={() => setActiveTab('createLocation')}
        >
          Add Location
        </button>
        <button
          style={activeTab === 'existingLocations' ? styles.activeTab : styles.tab}
          onClick={() => setActiveTab('existingLocations')}
        >
          Existing Locations
        </button>
      </div>

      <div style={styles.tabContent}>
        {activeTab === 'createLocation' && (
          <div>
            {createMessage && <p style={styles.message}>{createMessage}</p>}

            <form onSubmit={handleAddLocation} style={styles.form} autoComplete="off">
              <div style={styles.gridContainer}>
                <input
                  type="text"
                  name="name"
                  placeholder="Location Name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                  style={styles.input}
                  autoComplete="off"
                />
                <select
                  name="isactive"
                  value={formData.isactive}
                  onChange={handleInputChange}
                  style={styles.input}
                >
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </select>
              </div>
              <div style={styles.buttonContainer}>
                <button type="submit" style={styles.saveButton}>
                  Add Location
                </button>
              </div>
            </form>
          </div>
        )}

        {activeTab === 'existingLocations' && (
          <div>
            {updateMessage && <p style={styles.message}>{updateMessage}</p>}

            <input
              type="text"
              placeholder="Search locations..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={styles.searchInput}
              autoComplete="off"
            />
            <table style={styles.table}>
              <thead>
                <tr>
                  <th style={styles.th}>Name</th>
                  <th style={styles.th}>Active</th>
                  <th style={styles.th}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredLocations.map((location) => (
                  <tr key={location.id}>
                    {editingLocationId === location.id ? (
                      <>
                        <td style={styles.td}>
                          <input
                            type="text"
                            name="name"
                            value={editingLocationData.name}
                            onChange={(e) => setEditingLocationData((prev) => ({
                              ...prev,
                              name: e.target.value,
                            }))}
                            style={styles.input}
                          />
                        </td>
                        <td style={styles.td}>
                          <select
                            name="isactive"
                            value={editingLocationData.isactive}
                            onChange={(e) => setEditingLocationData((prev) => ({
                              ...prev,
                              isactive: e.target.value,
                            }))}
                            style={styles.input}
                          >
                            <option value="true">Active</option>
                            <option value="false">Inactive</option>
                          </select>
                        </td>
                        <td style={styles.td}>
                          <button onClick={handleSaveEdit} style={styles.saveButton}>
                            Save
                          </button>
                          <button onClick={handleCancelEdit} style={styles.cancelButton}>
                            Cancel
                          </button>
                        </td>
                      </>
                    ) : (
                      <>
                        <td style={styles.td}>{location.name}</td>
                        <td style={styles.td}>{location.isactive ? 'Active' : 'Inactive'}</td>
                        <td style={styles.td}>
                          <button onClick={() => startEditing(location)} style={styles.editButton}>
                            Edit
                          </button>
                          {editingLocationId !== location.id && (
                            <button onClick={() => generateQRCode(location.id)} style={styles.printButton}>
                              Print QR Code
                            </button>
                          )}
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

const styles = {
  container: {
    padding: '20px',
    maxWidth: '800px',
    margin: 'auto',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  cancelButtonContainer: {
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cancelButton: {
    padding: '10px 20px',
    fontSize: '14px',
    color: '#fff',
    backgroundColor: '#f44336',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
  tabHeader: {
    display: 'flex',
    borderBottom: '1px solid #ddd',
    marginBottom: '20px',
  },
  tab: {
    flex: 1,
    padding: '10px',
    cursor: 'pointer',
    textAlign: 'center',
    backgroundColor: '#f0f0f0',
    border: 'none',
    fontSize: '16px',
  },
  activeTab: {
    flex: 1,
    padding: '10px',
    cursor: 'pointer',
    textAlign: 'center',
    backgroundColor: '#ffffff',
    borderBottom: '2px solid #007bff',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  tabContent: {
    padding: '20px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '15px',
  },
  input: {
    padding: '10px',
    fontSize: '16px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '10px',
  },
  saveButton: {
    padding: '12px 20px',
    fontSize: '16px',
    color: '#fff',
    backgroundColor: '#4CAF50',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    marginRight: '5px',
  },
  searchInput: {
    padding: '10px',
    width: '100%',
    borderRadius: '4px',
    border: '1px solid #ddd',
    fontSize: '16px',
    marginBottom: '20px',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    padding: '10px',
    borderBottom: '1px solid #ddd',
    textAlign: 'left',
    fontWeight: 'bold',
  },
  td: {
    padding: '10px',
    borderBottom: '1px solid #ddd',
  },
  editButton: {
    padding: '8px 12px',
    fontSize: '14px',
    color: '#fff',
    backgroundColor: '#2196F3',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
  printButton: {
    padding: '8px 12px',
    fontSize: '14px',
    color: '#fff',
    backgroundColor: '#FFA500',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
  message: {
    marginBottom: '20px',
    fontSize: '16px',
    color: '#4CAF50',
    textAlign: 'center',
  },
};

export default LocationEdit;
