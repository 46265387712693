import axios from 'axios';

const API = axios.create({
 //baseURL: "http://localhost:8000",
    
    baseURL:  "https://cigli-hastane-rest.azurewebsites.net/"

});

export default API;
