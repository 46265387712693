import React, { useState } from 'react';
import API from '../services/api';
import { useNavigate } from 'react-router-dom';

function ChangePassword() {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();

  const handleChangePassword = async (e) => {
    e.preventDefault();
    try {
      const response = await API.put('/change_password', {
        current_password: currentPassword,
        new_password: newPassword,
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });

      setMessage(response.data.message);
      setCurrentPassword('');
      setNewPassword('');
      setTimeout(() => navigate('/home'), 3000);
    } catch (error) {
      console.error('Error changing password:', error);
      setMessage('Error changing password. Please try again.');
    }
  };


  const handleCancel = () => {
    navigate('/home');
  };

  return (
    <div style={styles.container}>
      <h2>Change Password</h2>
      {message && <p style={styles.message}>{message}</p>}
      <form onSubmit={handleChangePassword}>
        <input
          type="password"
          placeholder="Current Password"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          required
          style={styles.input}
        />
        <input
          type="password"
          placeholder="New Password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
          style={styles.input}
        />
        <div style={styles.buttonContainer}>
          
          <button type="button" onClick={handleCancel} style={styles.cancelButton}>Cancel</button>
          <button type="submit" style={styles.submitButton}>Submit</button>
        </div>
      </form>
    </div>
  );
}
const styles = {
  container: {
    padding: '20px',
    maxWidth: '800px',
    margin: 'auto',
    textAlign: 'center',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  input: {
    width: '90%',
    padding: '12px',
    margin: '10px 0',
    fontSize: '16px',
    borderRadius: '4px',
    border: '1px solid #ddd',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    gap: '10px',
    marginTop: '20px',
  },
  submitButton: {
    flex: 1,
    padding: '12px 0',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    fontSize: '16px',
    cursor: 'pointer',
    borderRadius: '5px',
    transition: 'background-color 0.3s ease',
    textAlign: 'center',
  },
  cancelButton: {
    flex: 1,
    padding: '12px 0',
    backgroundColor: '#f44336',
    color: 'white',
    border: 'none',
    fontSize: '16px',
    cursor: 'pointer',
    borderRadius: '5px',
    transition: 'background-color 0.3s ease',
    textAlign: 'center',
  },
  submitButtonHover: {
    backgroundColor: '#45a049', // Hover rengi
  },
  cancelButtonHover: {
    backgroundColor: '#d32f2f', // Hover rengi
  },
  message: {
    fontSize: '16px',
    color: '#4CAF50',
    marginTop: '10px',
  },
};

export default ChangePassword;
